;(function () {
  'use strict';

  let angular = window.angular;

  UserService.$inject = ['$q', 'Admin', 'Manager', 'LoopBackAuth', 'AppUtils'];

  angular
    .module('appServices')
    .service('UserService', UserService);

  function UserService ($q, Admin, Manager, LoopBackAuth, utils) {
    return {
      getCurrent: getCurrent,
      findById: findById,
      saveProfile: saveProfile,
      changePassword: changePassword,
      getUserImageUrl: getUserImageUrl,
      getModel: getModel,
      saveManager: saveManager
    };

    function getCurrent () {
      return Admin
        .getCurrent()
        .$promise;
    }

    function findById (userId, filter) {
      filter = filter || {};

      return Admin
        .findById({
          id: userId,
          filter: filter
        })
        .$promise;
    }

    function getUserImageUrl (user, property, modelName) {
      let model = modelName || getModel();
      property = property || '_profile';
      if (user.container && user.container[property]) {
        let file = user.container[property];
        property = property.replace('_', '');
        return utils.getEntityFileUrl(model, user.id, property, file, LoopBackAuth.accessTokenId);
      }
    }

    function getModel () {
      return Admin.modelName;
    }

    function changePassword (user, oldPassword, newPassword) {
      return Admin
        .changePassword({
          id: user.id
        }, {
          oldPassword: oldPassword,
          newPassword: newPassword
        })
        .$promise
        .catch(err => {
          return Promise.reject(utils.getHTTPError(err));
        });
    }

    function saveProfile (user) {
      return Admin
        .prototype$patchAttributes({
          id: user.id
        }, {
          name: user.name,
          surname: user.surname,
          //email: vm.user.email,
          phone: user.phone,
          birthday: user.birthday,
          address: user.address,
          countryId: user.countryId,
          timeZoneId: user.timeZoneId,
          passwordConfirmation: user.passwordConfirmation
        })
        .$promise
        .catch(err => {
          return Promise.reject(utils.getHTTPError(err));
        });
    }

    function saveManager (user) {
      return Manager
        .prototype$patchAttributes({
          id: user.id
        }, {
          name: user.name,
          surname: user.surname,
          //email: vm.user.email,
          phone: user.phone,
          birthday: user.birthday,
          address: user.address,
          countryId: user.countryId,
          timeZoneId: user.timeZoneId
        })
        .$promise
        .catch(err => {
          return Promise.reject(utils.getHTTPError(err));
        });
    }
  }
})();
