(function () {
  let angular = window.angular;

  CustomerFormController.$inject = ['Var', 'AppUtils', 'LocalStorageService'];

  angular.module('app').component('customerRemove', {
    template:'<div class="modal-header"><span translate>buttons.delete</span> <span translate>entities.organization.customer.modelName</span></div><div class="modal-body text-center"><p>You are about to remove the customer <strong>{{$ctrl.customer.name}}</strong></p><h5 class="marginB5"><strong>Are you Sure?</strong></h5></div><div ng-if="$ctrl.removeCustomerError" class="alert alert-warning text-center marginB0 marginT10">{{$ctrl.removeCustomerError}}</div><div class="modal-footer"><button class="btn btn-default pull-left" type="button" ng-click="$ctrl.modalInstance.dismiss()" translate>buttons.cancel</button> <button class="btn btn-danger" type="button" ng-click="$ctrl.removeCustomer()" translate>buttons.delete</button></div><spinner show="$ctrl.loading"></spinner>',
    controller: CustomerFormController,
    bindings: {
      modalInstance: '<',
      resolve: '<',
    },
  });

  function CustomerFormController(Var, utils, LocalStorageService) {
    let vm = this;

    vm.$onInit = function onInit() {
      vm.customer = vm.resolve.customer;
      vm.user = vm.resolve.user;
      vm.removeCustomer = removeCustomer;
    };

    function removeCustomer() {
      if (!vm.customer) {
        return;
      }

      vm.loading = true;
      Var.prototype$__destroyById__customers({ id: vm.user.varId, fk: vm.customer.id })
        .$promise.then(function () {
          vm.loading = false;
          LocalStorageService.clear();
          vm.modalInstance.close();
        })
        .catch(function (response) {
          vm.loading = false;
          vm.removeCustomerError = utils.getHTTPError(response);
        });
    }
  }
})();
