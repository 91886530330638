(function () {
  'use strict';

  TypeValueDisplayController.$inject = ['$scope', '$compile', '$element', '$timeout'];

  const angular = window.angular;
  angular.module('app').component('typeValueDisplay', {
    template: '<div class="content"></div>',
    controller: TypeValueDisplayController,
    controllerAs: 'ctrl',
    bindings: {
      $transition$: '<',
      sensor: '<',
      assetName: '<',
      state: '<',
      data: '<',
      disableClick: '<',
      onLoadContent: '<',
      filterByZone: '<',
      onlyContent: '<',
      asset: '<',
    },
  });

  function TypeValueDisplayController($scope, $compile, $element, $timeout) {
    let vm = this;
    let firstChange = true;

    vm.$postLink = function () {
      init();
      firstChange = false;
    };

    vm.$onDestroy = function () {
      if (vm.componentScope) {
        vm.componentScope.$destroy();
      }
    };

    vm.$onChanges = function (changes) {
      if (firstChange) {
        return;
      }

      if (changes.sensor) {
        init();
      } else {
        $scope.$applyAsync(() => {
          vm.componentScope.sensor = vm.sensor;
          vm.componentScope.assetName = vm.assetName;
          vm.componentScope.state = vm.state;
          vm.componentScope.data = vm.data;
          vm.componentScope.onLoadContent = vm.onLoadContent;
          vm.componentScope.filterByZone = vm.filterByZone;
          vm.componentScope.disableClick = vm.disableClick;
          vm.componentScope.onlyContent = vm.onlyContent;
          vm.componentScope.asset = vm.asset;
        });
      }
    };

    function init() {
      if (!vm.sensor) {
        return;
      }

      const content = vm.data ? vm.data.content : {};
      let type = vm.data?.type === 'Debug' && content.type ? content.type : vm.data?.type;
      type = type || vm.sensor.type;

      let componentMap = {
        ConcentrationAlert: 'rt-concentration-alert',
        File: 'rt-image',
        Number: 'number-gauge',
        SceneChange: 'sequence-data-display',
        Video: 'video-display',
        Checkout: 'checkout-data-display',
        MultiZoneObjectTracking: 'sequence-data-display',
        CargoContainerISOReader: 'iso-container-data-display',
      };

      let tagName = componentMap[type];

      if (!tagName) {
        tagName = 'detection-data-display';
      }

      if (vm.componentScope) {
        vm.componentScope.$destroy();
      }

      const componentScope = (vm.componentScope = $scope.$new(true));
      componentScope.sensor = vm.sensor;
      componentScope.assetName = vm.assetName;
      componentScope.state = vm.state;
      componentScope.data = vm.data;
      componentScope.onLoadContent = vm.onLoadContent;
      componentScope.filterByZone = vm.filterByZone;
      componentScope.disableClick = vm.disableClick;
      componentScope.onlyContent = vm.onlyContent;
      componentScope.asset = vm.asset;

      $element
        .find('.content')
        .empty()
        .append(
          $compile(`<${tagName}
        sensor="sensor"
        asset-name="assetName"
        state="state"
        data="data"
        on-load-content="onLoadContent"
        only-content="onlyContent"
        filter-by-zone="filterByZone"
        asset="asset"
        disable-click="disableClick"></${tagName}>`)(componentScope)
        );
    }
  }
})();
