(() => {
  const angular = window.angular;

  angular.module('app').config(ReportRouter);

  ReportRouter.$inject = ['$stateProvider'];

  function ReportRouter($stateProvider) {
    $stateProvider
      .state('member.customer.show.project.edge-monitoring', {
        url: '/edge-monitoring',
        component: 'healthMonitoringStatusShow',
        redirectTo: 'member.customer.show.project.edge-monitoring.uptime-realtime',
        authenticated: true,
        resolve: {
          module: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/vendors/d3.min.js',
                window.assetsPath + `/js/vendors/chart-vendors-${style}.min.js`,
                window.assetsPath + `/css/editors-${style}.css`,
                window.assetsPath + '/js/vendors/editors/jsoneditor.min.js',
                window.assetsPath + '/js/app/health-monitoring.module.min.js',
                window.assetsPath + `/css/health-monitoring.style-${style}.css`,
              ]);
            },
          ],
          devices: [
            'Customer',
            '$rootScope',
            (Customer, $root) => {
              return Customer.prototype$__get__devices({
                id: $root.customerId,
                filter: {
                  fields: ['id', 'name', 'enabled'],
                },
              }).$promise;
            },
          ],
          adminUsers: [
            'OrganizationService',
            'user',
            function (OrganizationService, user) {
              return OrganizationService.getUsers({ id: user.varId });
            },
          ],
          users: [
            'Customer',
            '$transition$',
            'AppUtils',
            function (Customer, $transition$, utils) {
              let params = $transition$.params();
              return Customer.managers({ id: params.customerId }).$promise.catch((err) => {
                throw utils.getHTTPError(err, {
                  redirectTo: 'member.customer.show.home',
                  params: { customerId: params.customerId },
                });
              });
            },
          ],
        },
        ncyBreadcrumb: {
          label: 'Edge Status',
        },
      })
      .state('member.customer.show.project.edge-monitoring.events', {
        url: '/events',
        component: 'edgeEventManagement',
        authenticated: true,
        resolve: {},
        ncyBreadcrumb: {
          label: 'Event Management',
        },
      })
      .state('member.customer.show.project.edge-monitoring.healthcheckEvents', {
        url: '/healthcheck-events',
        component: 'assetHealthcheckEventManagement',
        authenticated: true,
        resolve: {},
        ncyBreadcrumb: {
          label: 'Event Management',
        },
      })
      .state('member.customer.show.project.edge-monitoring.vital-signs', {
        url: '/vital-signs',
        component: 'edgeMonitoringStatusVitalSignsTable',
        authenticated: true,
        resolve: {},
        ncyBreadcrumb: {
          label: 'Edge Status Table',
        },
      })
      .state('member.customer.show.project.edge-monitoring.image-upload-monitoring', {
        url: '/image-upload',
        component: 'edgeMonitoringCameraBackground',
        authenticated: true,
        resolve: {},
        ncyBreadcrumb: {
          label: 'Image Upload monitoring',
        },
      })
      .state('member.customer.show.project.edge-monitoring.uptime-realtime', {
        url: '/uptime-realtime',
        component: 'uptimeRealtime',
        authenticated: true,
        resolve: {},
        ncyBreadcrumb: {
          label: 'Realtime Status',
        },
      });
  }
})();
