(function () {
  'use strict';

  let angular = window.angular;

  GisRouter.$inject = ['$stateProvider'];

  angular.module('app').config(GisRouter);

  function GisRouter($stateProvider) {
    $stateProvider
      .state('member.customer.show.project.gis', {
        url: '/gis',
        redirectTo: 'member.customer.show.project.gis.home',
        template: '<ui-view></ui-view>',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'GIS',
        },
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            function ($ocLazyLoad) {
              return $ocLazyLoad.load([window.assetsPath + '/js/app/gis.module.min.js']);
            },
          ],
          hasTool: [
            'tools',
            'AppUtils',
            function (tools, utils) {
              const hasTool = tools.find((tool) => tool.module === 'xompassGIS');
              if (!hasTool) {
                throw utils.getHTTPError(new Error('Not allowed'), {
                  redirectTo: 'member.customer.invalidTool',
                  params: { tool: 'xompassGIS' },
                });
              }
            },
          ],
        },
      })
      .state('member.customer.show.project.gis.home', {
        url: '/',
        component: 'gisHome',
        authenticated: true,
        resolve: {
          gis: [
            'EntityService',
            '$transition$',
            function (EntityService, $transition$) {
              const options = angular.copy($transition$.params());
              options.filter = { where: { type: 'GENERIC' } };
              return EntityService.find('Gis', options);
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{"general.gallery" | translate}}',
        },
      })
      .state('member.customer.show.project.gis.new', {
        url: '/new',
        component: 'gisEditor',
        authenticated: true,
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/vendors/gmap-vendors.min.js',
                window.assetsPath + `/js/vendors/chart-vendors-${style}.min.js`,
              ]);
            },
          ],
        },
        ncyBreadcrumb: {
          label: 'New',
        },
      })
      .state('member.customer.show.project.gis.show', {
        url: '/:gisId',
        component: 'gisShow',
        redirectTo: 'member.customer.show.project.gis.show.viewer',
        authenticated: true,
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/vendors/gmap-vendors.min.js',
                window.assetsPath + `/js/vendors/chart-vendors-${style}.min.js`,
              ]);
            },
          ],
          gisModel: [
            'EntityService',
            '$transition$',
            'AppUtils',
            function (EntityService, $transition$, utils) {
              return loadGIS(EntityService, $transition$.params(), utils, 'GENERIC');
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{$resolve.gisModel.name}}',
        },
      })
      .state('member.customer.show.project.gis.show.viewer', {
        url: '/viewer',
        component: 'gisViewer',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Viewer',
        },
      })
      .state('member.customer.show.project.gis.show.editor', {
        url: '/editor',
        component: 'gisEditor',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Editor',
        },
      })

      // WILDFIRE
      .state('member.customer.show.project.wildfire', {
        url: '/wildfire',
        redirectTo: 'member.customer.show.project.wildfire.home',
        template: '<ui-view></ui-view>',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Wildfire',
        },
        resolve: {
          hasTool: [
            'tools',
            'AppUtils',
            function (tools, utils) {
              const hasTool = tools.find((tool) => tool.module === 'xompassWildfireGis');
              if (!hasTool) {
                throw utils.getHTTPError(new Error('Not allowed'), {
                  redirectTo: 'member.customer.invalidTool',
                  params: { tool: 'xompassWildfireGis' },
                });
              }
            },
          ],
        },
      })
      .state('member.customer.show.project.wildfire.home', {
        url: '/',
        component: 'wildFireHome',
        authenticated: true,
        resolve: {
          gis: [
            'EntityService',
            '$transition$',
            function (EntityService, $transition$) {
              const options = angular.copy($transition$.params());
              options.filter = { where: { type: 'WILDFIRE' } };
              return EntityService.find('Gis', options);
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{"general.gallery" | translate}}',
        },
      })
      .state('member.customer.show.project.wildfire.new', {
        url: '/new',
        component: 'wildFireEditor',
        authenticated: true,
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/vendors/gmap-vendors.min.js',
                window.assetsPath + `/js/vendors/chart-vendors-${style}.min.js`,
              ]);
            },
          ],
        },
        ncyBreadcrumb: {
          label: 'New',
        },
      })
      .state('member.customer.show.project.wildfire.show', {
        url: '/:gisId',
        component: 'wildFireShow',
        redirectTo: 'member.customer.show.project.wildfire.show.hazard',
        authenticated: true,
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/vendors/gmap-vendors.min.js',
                window.assetsPath + `/js/vendors/chart-vendors-${style}.min.js`,
              ]);
            },
          ],
          gisModel: [
            'EntityService',
            '$transition$',
            'AppUtils',
            function (EntityService, $transition$, utils) {
              return loadGIS(EntityService, $transition$.params(), utils, 'WILDFIRE');
            },
          ],
          assets: [
            'Customer',
            'AppUtils',
            '$transition$',
            'gisModel',
            (Customer, utils, $transition$, gisModel) => {
              const params = $transition$.params();
              return loadGISAssets(Customer, params.customerId, gisModel);
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{$resolve.gisModel.name}}',
        },
      })
      .state('member.customer.show.project.wildfire.show.hazard', {
        url: '/hazard',
        component: 'wildFireHazard',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Hazard',
        },
      })
      .state('member.customer.show.project.wildfire.show.monitoring', {
        url: '/monitoring',
        component: 'wildFireMonitoring',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Monitoring',
        },
      })
      .state('member.customer.show.project.wildfire.show.editor', {
        url: '/editor',
        component: 'wildFireViewer',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Editor',
        },
      })

      // FIBER
      .state('member.customer.show.project.fiber', {
        url: '/fiber',
        redirectTo: 'member.customer.show.project.fiber.home',
        template: '<ui-view></ui-view>',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Optical Fiber GIS',
        },
        resolve: {
          hasTool: [
            'tools',
            'AppUtils',
            function (tools, utils) {
              const hasTool = tools.find((tool) => tool.module === 'xompassOpticalFiberGis');
              if (!hasTool) {
                throw utils.getHTTPError(new Error('Not allowed'), {
                  redirectTo: 'member.customer.invalidTool',
                  params: { tool: 'xompassOpticalFiberGis' },
                });
              }
            },
          ],
        },
      })
      .state('member.customer.show.project.fiber.home', {
        url: '/',
        component: 'fiberHome',
        authenticated: true,
        resolve: {
          gis: [
            'EntityService',
            '$transition$',
            function (EntityService, $transition$) {
              const options = angular.copy($transition$.params());
              options.filter = { where: { type: 'OPTICAL_FIBER' } };
              return EntityService.find('Gis', options);
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{"general.gallery" | translate}}',
        },
      })
      .state('member.customer.show.project.fiber.new', {
        url: '/new',
        component: 'fiberEditor',
        authenticated: true,
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/vendors/gmap-vendors.min.js',
                window.assetsPath + `/js/vendors/chart-vendors-${style}.min.js`,
              ]);
            },
          ],
        },
        ncyBreadcrumb: {
          label: 'New',
        },
      })
      .state('member.customer.show.project.fiber.show', {
        url: '/:gisId',
        component: 'fiberShow',
        redirectTo: 'member.customer.show.project.fiber.show.viewer',
        authenticated: true,
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/vendors/gmap-vendors.min.js',
                window.assetsPath + `/js/vendors/chart-vendors-${style}.min.js`,
              ]);
            },
          ],
          gisModel: [
            'EntityService',
            '$transition$',
            'AppUtils',
            function (EntityService, $transition$, utils) {
              return loadGIS(EntityService, $transition$.params(), utils, 'FIBER');
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{$resolve.gisModel.name}}',
        },
      })
      .state('member.customer.show.project.fiber.show.viewer', {
        url: '/viewer',
        component: 'fiberViewer',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Viewer',
        },
      })
      .state('member.customer.show.project.fiber.show.editor', {
        url: '/editor',
        component: 'fiberEditor',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Editor',
        },
      });

    function loadGIS(EntityService, params, utils, type) {
      const redirect = {
        WILDFIRE: 'member.customer.show.project.wildfire.home',
        GENERIC: 'member.customer.show.project.gis.home',
        FIBER: 'member.customer.show.project.fiber.home',
      };

      const options = angular.copy(params);
      options.entityId = options.gisId;
      options.filter = { include: { relation: 'layers', scope: { include: 'features' } } };

      return EntityService.findById('Gis', options).catch((err) => {
        console.log(err);
        throw utils.getHTTPError(err, {
          redirectTo: redirect[type],
          params: { customerId: params.customerId, projectId: params.projectId },
        });
      });
    }

    function loadGISAssets(Customer, customerId, gis) {
      const assetIds = [];
      gis.layers.forEach((layer) => {
        layer.features.forEach((feature) => {
          if (!feature.assetId) {
            return;
          }
          assetIds.push(feature.assetId);
        });
      });
      return Customer.assets({
        id: customerId,
        filter: {
          where: { id: { inq: assetIds } },
          include: ['sensors', 'assetStates', 'currentState'],
          fields: {
            _token: false,
            _config: false,
            created: false,
            deleted: false,
            modified: false,
            path: false,
            customerId: false,
            projectId: false,
          },
        },
      }).$promise;
    }
  }
})();
