(function () {
  'use strict';

  let angular = window.angular;

  Controller.$inject = ['$translate'];

  angular.module('app').component('missingPhoneModal', {
    template:'<div class="modal-header"><span>Restablecer 2FA</span></div><div class="modal-body text-center"><p>Para desactivar el segundo factor de seguridad, envíe un correo a <strong>support@vsaas.ai</strong>.</p></div><div class="modal-footer"><button class="btn btn-default text-center" ng-click="ctrl.modalInstance.dismiss()">Aceptar</button></div><spinner css-class="\'small\'" show-logo="true" show="ctrl.loading"></spinner>',
    controller: Controller,
    controllerAs: 'ctrl',
    bindings: {
      modalInstance: '<',
      resolve: '<',
    },
  });

  function Controller($translate) {
    let vm = this;

    vm.$onInit = function onInit() {};
  }
})();
