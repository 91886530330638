;(function () {
  'use strict';

  let angular = window.angular;

  BIPanelRouter.$inject = ['$stateProvider'];

  angular
    .module('app')
    .config(BIPanelRouter);

  function BIPanelRouter ($stateProvider) {
    $stateProvider
      .state('member.customer.show.project.camera-grid', {
        url: '/camera-grid',
        component: 'cameraGrid',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Camera Grid'
        },
        resolve: {
          module: ['$ocLazyLoad', function ($ocLazyLoad) {
            return $ocLazyLoad.load([
              window.assetsPath + '/js/app/camera-grid.module.min.js',
            ]);
          }],
          assets: ['Customer', '$transition$', function (Customer, $transition$) {
            let params = $transition$.params();
            return Customer
              .prototype$__get__projects__assets({
                id: params.customerId,
                nk: params.projectId,
                filter: {
                  fields: ['id', 'name', 'icon', 'assetId', 'type'],
                  include: 'container'
                }
              })
              .$promise;
          }],
        }
      });
  }
})();
