;(function () {
  'use strict';

  const angular = window.angular;

  CustomerListController.$inject = ['$element', '$uibModal', '$state', '$translate'];

  angular
    .module('app')
    .component('customerList', {
      template:'<div class="container"><h2><span translate>entities.organization.customer.modelNamePl</span><div class="btn-group btn-group-sm pull-right"><button class="btn btn-primary" ng-click="$ctrl.showCustomerForm()"><i class="fas fa-plus"></i> <span translate>buttons.new</span></button></div></h2><div class="well well-light well-sm no-padding"><div class="margin5"><table id="customer-table" class="table table-hover no-wrap" style="width: 100%"><thead><tr><th></th><th style="width: 20px"></th><th style="width: 20px"></th><th style="width: 20px"></th></tr></thead></table></div></div></div>',
      controller: CustomerListController,
      bindings: {
        user: '<',
        customers: '<',
        timeZones: '<',
        countries: '<',
      },
    });

  function CustomerListController($element, $uibModal, $state, $translate) {

    let vm = this;

    vm.$onInit = function () {
      vm.showCustomerForm = showCustomerForm;
      initCustomerTable([].concat(vm.customers));
    };

    function showCustomerForm(customer) {
      let instance = $uibModal
        .open({
          animation: true,
          bindToController: true,
          component: 'customerForm',
          backdrop: 'static',
          keyboard: false,
          resolve: {
            customer: () => customer,
            timeZones: () => vm.timeZones,
            countries: () => vm.countries,
          },
        });

      instance
        .result
        .then(function (result) {
          if (!result) {
            return;
          }

          $state.reload();
        })
        .catch(function (err) {
          console.log(err);
        });
    }

    function showCustomerRemove(customer) {
      let instance = $uibModal
        .open({
          animation: true,
          bindToController: true,
          component: 'customerRemove',
          backdrop: 'static',
          keyboard: false,
          resolve: {
            customer: function () {
              return customer;
            },
            user: function () {
              return vm.user;
            },
          },
        });

      instance
        .result
        .then(function (result) {
          if (!result) {
            return;
          }

          $state.reload();
        })
        .catch(function (err) {
          console.log(err);
        });
    }

    function initCustomerTable(data) {
      let dtOptions = {
        processing: true,
        info: false,
        pageLength: 15,
        lengthMenu: [15, 25, 50, 75, 100],
        data: data,
        columns: [
          {
            data: 'name',
            title: $translate.instant('entities._all.name'),
          }, {
            data: 'id',
            orderable: false,
            render: function (data) {
              return `<a class="btn btn-xs btn-info" href="/member/customer/${data}"><i class="fas fa-fw fa-arrow-right"></i></a>`;
            },
          }, {
            data: 'id',
            orderable: false,
            render: function (data) {
              return `<a class="btn btn-xs btn-warning edit" data-customer="${data}"><i class="fas fa-fw fa-edit"></i></a>`;
            },
          }, {
            data: 'id',
            orderable: false,
            render: function (data) {
              return `<a class="btn btn-xs btn-danger remove" data-customer="${data}"><i class="fas fa-fw fa-times"></i></a>`;
            },
          },
        ],
      };

      let table = $element
        .find('#customer-table');

      table.DataTable(dtOptions);
      table
        .on('click', '.edit', function () {
          let customerId = $(this).data('customer');
          let customer = vm.customers.find(customer => customer.id === customerId);

          showCustomerForm(customer);
        })
        .on('click', '.remove', function () {
          let customerId = $(this).data('customer');
          let customer = vm.customers.find(customer => customer.id === customerId);

          showCustomerRemove(customer);
        });

    }
  }
})();
