;
(function () {
  'use strict';

  let angular = window.angular;

  OperationsStatisticsRouter.$inject = ['$stateProvider'];

  angular
    .module('app')
    .config(OperationsStatisticsRouter);

  function OperationsStatisticsRouter ($stateProvider) {
    $stateProvider
      .state('member.customer.show.project.operationsStatistics', {
        url: '/operations-statistics',
        component: 'operationsStatisticsComponent',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Operations Statistics'
        },
        resolve: {
          dependencies: ['$ocLazyLoad', function ($ocLazyLoad) {
            return $ocLazyLoad.load([
              window.assetsPath + '/js/app/operations-statistics.module.min.js'
            ]);
          }],
          param: [() => {
            return Promise.resolve('...');
          }]
        }
      });
  }
})();
