(function () {
  'use strict';

  const angular = window.angular;

  AppConfig.$inject = [
    '$locationProvider',
    'LoopBackResourceProvider',
    '$translateProvider',
    '$breadcrumbProvider',
    '$showdownProvider',
    'LANGS',
  ];

  angular.module('app').config(AppConfig);

  function AppConfig(
    $locationProvider,
    LoopBackResourceProvider,
    $translateProvider,
    $breadcrumbProvider,
    $showdownProvider,
    LANGS
  ) {
    $locationProvider.html5Mode(true).hashPrefix('#');
    LoopBackResourceProvider.setUrlBase(window.apiPath);
    $showdownProvider.setOption('tables', true);
    $showdownProvider.setOption('sanitize', true);

    $translateProvider.useStaticFilesLoader({
      prefix: window.assetsPath + '/lang/',
      suffix: '.json',
    });

    const currentLang = window.localStorage.getItem('lang');

    if (currentLang && LANGS.indexOf(currentLang) !== -1) {
      $translateProvider.preferredLanguage(currentLang);
      //moment.locale(currentLang);
    } else {
      $translateProvider.determinePreferredLanguage();
    }

    let lang = $translateProvider.preferredLanguage();
    if (LANGS.indexOf(lang) === -1) {
      lang = lang.substring(0, lang.indexOf('_')).toLowerCase();

      if (LANGS.indexOf(lang) === -1) {
        $translateProvider.fallbackLanguage('en');
        document.documentElement.lang = 'en';
      } else {
        document.documentElement.lang = currentLang;
      }
    } else {
      document.documentElement.lang = lang;
    }

    $translateProvider.useSanitizeValueStrategy('escaped');

    const options = {};
    LANGS.forEach((current) => {
      options[current + '_*'] = current;
    });

    $translateProvider.registerAvailableLanguageKeys(LANGS, options);

    //$translateProvider.useLoaderCache(true)

    $translateProvider.useMissingTranslationHandler('TranslateReplacementFactory');

    $breadcrumbProvider.setOptions({
      includeAbstract: true,
      template: `<ol class="breadcrumb">
        <li ng-repeat="step in steps | limitTo:(steps.length-1)" ng-attr-title="{{step.ncyBreadcrumbLabel}}">
            <a href="{{step.ncyBreadcrumbLink}}" ng-bind-html="step.ncyBreadcrumbLabel"></a>
        </li>
        <li ng-repeat="step in steps | limitTo:-1" class="active" ng-attr-title="{{step.ncyBreadcrumbLabel}}">
            <span ng-bind-html="step.ncyBreadcrumbLabel"></span>
        </li>
      </ol>`,
    });
  }
})();
