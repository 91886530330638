(function () {
  'use strict';

  let angular = window.angular;

  vmsRouter.$inject = ['$stateProvider'];

  angular.module('app').config(vmsRouter);

  function vmsRouter($stateProvider) {
    $stateProvider.state('member.customer.show.project.vms', {
      url: '/vms',
      component: 'vmsViewer',
      authenticated: true,
      //redirectTo: 'member.customer.show.project.vms.viewer',
      ncyBreadcrumb: {
        label: '{{"modules.vms.breadcrumb" | translate}}',
      },
      resolve: {
        dependencies: [
          '$ocLazyLoad',
          '$rootScope',
          function ($ocLazyLoad, $root) {
            const style = $root.darkMode ? 'dark' : 'light';
            return $ocLazyLoad.load([
              window.assetsPath + `/js/vendors/chart-vendors-${style}.min.js`,
              window.assetsPath + `/css/vms.style-${style}.css`,
              window.assetsPath + '/js/app/vms.module.min.js',
            ]);
          },
        ],
      },
    });
  }
})();
