(function () {
  'use strict';

  let angular = window.angular;

  PublicRouter.$inject = ['$stateProvider'];

  angular.module('app').config(PublicRouter);

  function PublicRouter($stateProvider, utils) {
    $stateProvider
      .state('member', {
        url: '/member?:style',
        redirectTo: 'member.home',
        authenticated: true,
        template:'<div ng-if="$root.systemMessage.show || $root.appErrorMessage" class="system-message"><div class="text-center no-margin alert alert-{{$root.systemMessage.level || \'info\'}}" ng-if="$root.systemMessage.show"><button class="close" ng-click="$root.systemMessage.show=false" ng-if="$root.systemMessage.allowClose"><i class="fas fa-times fa-fw" style="font-size: 15px"></i></button><div ng-bind-html="$root.systemMessage.text"></div></div><div class="text-center no-margin alert alert-{{$root.appErrorMessage.level || \'info\'}}" ng-if="$root.appErrorMessage.show"><button class="close" ng-if="$root.appErrorMessage.allowClose" ng-click="$root.appErrorMessage.show=false"><i class="fas fa-times fa-fw" style="font-size: 15px"></i></button><div ng-bind-html="$root.appErrorMessage.text"></div></div></div><div class="absolute" ng-style="{top: $root.systemMessage.show || $root.appErrorMessage.show? \'36px\': 0}"><nav-bar></nav-bar><div id="page-wrapper"><div class="absolute" style="overflow-y: auto"><ui-view></ui-view></div><spinner show="$root.viewLoading" css-class="\'dark\'"></spinner></div></div>',
        params: {
          style: { type: 'string', inherit: true },
        },
        resolve: {
          user: [
            'UserService',
            'RouterService',
            'AppUtils',
            '$rootScope',
            function (UserService, RouterService, utils, $root) {
              return UserService.getCurrent()
                .then((current) => {
                  RouterService.storeId(current.id, 'Principal');
                  return RouterService.getPrincipal(current);
                })
                .then((user) => {
                  $root.isAdmin = true;
                  user.isAdmin = true;
                  user.image = UserService.getUserImageUrl(user);

                  $root.user = user;
                  return user;
                })
                .catch((err) => {
                  throw utils.getHTTPError(err, { redirectTo: 'public.login' });
                });
            },
          ],
          isAdmin: () => true,
          customers: [
            'navbarService',
            'user',
            'RouterService',
            function (navbarService, user, RouterService) {
              return RouterService.getVarCustomers(user.varId).then((customers) => {
                navbarService.setCustomers(customers);
                return customers;
              });
            },
          ],
        },
        ncyBreadcrumb: {
          label: '<i class="fas fa-home"></i>',
        },
      })
      .state('member.home', {
        url: '/home',
        authenticated: true,
        component: 'memberHome',
        ncyBreadcrumb: {
          label: 'Home',
        },
      })

      .state('member.applications', {
        url: '/applications',
        authenticated: true,
        template: '<ui-view/>',
        redirectTo: 'member.applications.list',
        ncyBreadcrumb: {
          label: '<span>{{"entities.toolkit.modelNamePl" | translate}}</span>',
        },
      })
      .state('member.applications.list', {
        url: '/',
        authenticated: true,
        component: 'toolkitList',
        resolve: {
          toolKits: [
            'Var',
            'user',
            function (Var, user) {
              return Var.prototype$__get__toolkits({ id: user.varId, filter: { include: 'tools' } })
                .$promise;
            },
          ],
        },
        ncyBreadcrumb: {
          skip: true,
        },
      })
      .state('member.applications.show', {
        url: '/:toolkitId',
        authenticated: true,
        template:'<div class="page-header"><h1 class="no-margin"><i class="fas fa-window-restore fa-fw"></i> <span class="header-text">{{ctrl.toolKit.name}}</span></h1><div class="container"><ul class="nav nav-tabs nav-justified"><li ui-sref-active="active"><a ui-sref=".overview">Información</a></li><li ui-sref-active="active"><a ui-sref=".configuration">Configuración</a></li></ul></div></div><ui-view></ui-view>',
        redirectTo: 'member.applications.show.overview',
        controller: [
          'toolKit',
          function (toolKit) {
            this.toolKit = toolKit;
          },
        ],
        controllerAs: 'ctrl',
        resolve: {
          toolKit: [
            'Var',
            'user',
            '$transition$',
            function (Var, user, $transition$) {
              let params = $transition$.params();
              return Var.prototype$__get__toolkits({
                id: user.varId,
                filter: { include: 'tools', where: { id: params.toolkitId } },
              }).$promise.then((result) => {
                return result[0];
              });
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{$resolve.toolKit.name}}',
        },
      })
      .state('member.applications.show.overview', {
        url: '/overview',
        authenticated: true,
        component: 'toolkitOverview',
        ncyBreadcrumb: {
          label: 'Información',
        },
      })
      .state('member.applications.show.configuration', {
        url: '/configuration',
        authenticated: true,
        component: 'toolkitConfiguration',
        ncyBreadcrumb: {
          label: 'Configuración',
        },
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/vendors/editors/simplemde.min.js',
                window.assetsPath + `/css/editors-${style}.css`,
              ]);
            },
          ],
        },
      })

      .state('member.tools', {
        url: '/tools',
        authenticated: true,
        template: '<ui-view/>',
        ncyBreadcrumb: {
          label: 'Herramientas',
        },
      })
      .state('member.tools.show', {
        url: '/:toolId',
        authenticated: true,
        component: 'toolShow',
        resolve: {
          tool: [
            'Var',
            'user',
            '$transition$',
            function (Var, user, $transition$) {
              let params = $transition$.params();
              return Var.prototype$__get__tools({
                id: user.varId,
                filter: { where: { id: params.toolId } },
              })
                .$promise.then((result) => {
                  return result[0];
                })
                .catch((err) => {
                  console.log(err);
                });
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{$resolve.tool.name}}',
        },
      });
  }
})();
