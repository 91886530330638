(function () {
  'use strict';

  let angular = window.angular;

  ProcessLayoutRouter.$inject = ['$stateProvider'];

  angular.module('app').config(ProcessLayoutRouter);

  function ProcessLayoutRouter($stateProvider) {
    $stateProvider
      .state('member.customer.show.project.scada', {
        url: '/process-layout',
        redirectTo: 'member.customer.show.project.scada.home',
        template: '<ui-view></ui-view>',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Process Layout',
        },
        resolve: {
          hasTool: [
            'tools',
            'AppUtils',
            function (tools, utils) {
              const hasTool = tools.find((tool) => tool.module === 'xompassSCADA');
              if (!hasTool) {
                throw utils.getHTTPError(new Error('Not allowed'), {
                  redirectTo: 'member.customer.invalidTool',
                  params: { tool: 'xompassSCADA' },
                });
              }
            },
          ],
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/vendors/scada-vendors.min.js',
                window.assetsPath + `/js/vendors/chart-vendors-${style}.min.js`,
              ]);
            },
          ],
          module: [
            '$ocLazyLoad',
            'dependencies',
            function ($ocLazyLoad) {
              return $ocLazyLoad.load([window.assetsPath + '/js/app/scada.module.min.js']);
            },
          ],
        },
      })
      .state('member.customer.show.project.scada.home', {
        url: '/',
        component: 'scadaHome',
        authenticated: true,
        resolve: {
          scadas: [
            'EntityService',
            '$transition$',
            function (EntityService, $transition$) {
              const options = angular.copy($transition$.params());
              options.filter = { include: ['container'] };
              return EntityService.find('ProcessLayout', options);
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{"general.gallery" | translate}}',
        },
      })
      .state('member.customer.show.project.scada.new', {
        url: '/new',
        component: 'scadaEditor',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'New',
        },
      })

      .state('member.customer.show.project.scada.show', {
        url: '/:scadaId',
        component: 'scadaShow',
        redirectTo: 'member.customer.show.project.scada.show.viewer',
        authenticated: true,
        resolve: {
          scada: [
            'EntityService',
            '$transition$',
            'AppUtils',
            function (EntityService, $transition$, utils) {
              return loadSCADA(EntityService, $transition$.params(), utils);
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{$resolve.scada.name}}',
        },
      })

      .state('member.customer.show.project.scada.show.viewer', {
        url: '/viewer',
        component: 'scadaViewer',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Viewer',
        },
      })
      .state('member.customer.show.project.scada.show.editor', {
        url: '/editor',
        component: 'scadaEditor',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Editor',
        },
      });

    function loadSCADA(EntityService, params, utils) {
      const options = angular.copy(params);
      options.entityId = options.scadaId;

      return EntityService.findById('ProcessLayout', options).catch((err) => {
        console.log(err);
        throw utils.getHTTPError(err, {
          redirectTo: 'member.customer.show.project.scada.home',
          params: { customerId: params.customerId, projectId: params.projectId },
        });
      });
    }
  }
})();
