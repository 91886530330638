;(() => {
  const angular = window.angular;

  angular
    .module('app')
    .config(ReportRouter);

  ReportRouter.$inject = ['$stateProvider'];

  function ReportRouter ($stateProvider) {
    $stateProvider
      .state('member.customer.show.project.tutorial', {
        url: '/tutorial-pascal',
        component: 'helloWorld',
        authenticated: true,
        resolve: {
          module: ['$ocLazyLoad', function ($ocLazyLoad) {
            return $ocLazyLoad.load([
              window.assetsPath + '/js/app/tutorial.module.min.js',
              //añadir cdn de ag.grid para probar
            ]);
          }],
        },
        ncyBreadcrumb: {
          label: 'Tutorial Pascal'
        }
      });
  }
})();
