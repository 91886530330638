;(function () {
  'use strict';

  let angular = window.angular;
  angular
    .module('app', [
      'lbServices',
      'oc.lazyLoad',
      'commonServices',
      'appServices',
      'ngSanitize',
      'ngAnimate',
      'ngMessages',
      'ui.router',
      'ui.select',
      'ui.bootstrap',
      'ui.bootstrap.datetimepicker',
      'ng-showdown',
      'pascalprecht.translate',
      'ncy-angular-breadcrumb',
      'ui.bootstrap.showErrors',
      'angularSpectrumColorpicker',
      'angular-clipboard',
      'angular-uuid',
      'angular-storage',
      'angularMoment',
      'mgo-angular-wizard',
    ]);
})();
