;(function () {
  'use strict';

  const angular = window.angular;

  AccountConfirmationController.$inject = ['Admin', 'LoopBackAuth', '$rootScope', '$state', 'AppUtils', '$timeout'];

  angular
    .module('app')
    .component('publicAccountConfirmation', {
      template:'<section class="login-container"><div class="login-header"><div class="login-brand"></div><h3 class="no-margin" translate>views.public.confirmAccount.title</h3></div><div class="login-content text-center" ng-if="!$ctrl.error"><h4 class="text-success"><strong translate>views.public.confirmAccount.success</strong></h4><p class="marginB20 paddingB20" translate>views.public.confirmAccount.message</p><div class="text-center marginT20"><a ui-sref="public.login" translate>buttons.signin</a></div></div><div class="login-content text-center" ng-if="$ctrl.error"><h4 class="text-danger"><strong translate>errors.UNKNOWN_ERROR</strong></h4><div ng-show="$ctrl.error" ng-switch on="$ctrl.error.code" class="marginB20 paddingB20"><div ng-switch-when="INVALID_TOKEN"><span translate>views.public.confirmAccount.invalidToken</span><div class="text-center marginT20"><a ui-sref="public.login" translate>buttons.signin</a></div></div><div ng-switch-default><span>{{\'errors.\' + $ctrl.error | translate}}</span></div></div></div></section>',
      controller: AccountConfirmationController
    });

  function AccountConfirmationController (Admin, LoopBackAuth, $root, $state, utils, $timeout) {
    let vm = this;

    this.$onInit = function () {
      vm.uid = $state.params.uid;
      vm.token = $state.params.token;

      $root.appReady = false;
      Admin
        .confirm({
          uid: vm.uid,
          token: vm.token
        })
        .$promise
        .then(() => {
          $root.appReady = true;
          $timeout(() => {
            $state.go('public.login');
          }, 15000);
        })
        .catch(err => {
          $root.appReady = true;
          vm.error = utils.getHTTPError(err)
        });
    };
  }
})();
