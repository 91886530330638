(function () {
  'use strict';

  const angular = window.angular;

  HomeController.$inject = ['$uibModal', '$state'];

  angular.module('app').component('customerProjects', {
    template:'<h2 class="text-info marginT0" translate>entities.project.modelNamePl</h2><hr class="marginT0"><div class="home-shortcuts"><a class="shortcut" ng-click="$ctrl.showProjectForm()"><div class="shortcut-content primary"><div class="link-icon"><i class="fas fa-plus"></i></div><div class="link-text-wrapper"><div class="link-text" translate>buttons.create</div></div></div></a> <a class="shortcut" ng-repeat="project in $ctrl.projects" ui-sref="member.customer.show.project.home({projectId: project.id})"><div class="shortcut-content"><div class="link-icon"><i class="fas fa-folder"></i></div><div class="link-text-wrapper" title="{{project.name}}"><div class="link-text nowrap">{{project.name}}</div></div></div></a></div>',
    controller: HomeController,
    bindings: {
      customer: '<',
      projects: '<',
      user: '<',
    },
  });

  function HomeController($uibModal, $state) {
    let vm = this;

    vm.$onInit = function () {
      vm.showProjectForm = showProjectForm;
      vm.showCustomerForm = showCustomerForm;
    };

    function showCustomerForm(customer) {
      let instance = $uibModal.open({
        animation: true,
        bindToController: true,
        component: 'customerForm',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          customer: function () {
            return customer;
          },
        },
      });

      instance.result
        .then(function (result) {
          if (!result) {
            return;
          }

          $state.reload();
        })
        .catch(function (err) {
          console.log(err);
        });
    }

    function showProjectForm(project) {
      let instance = $uibModal.open({
        animation: true,
        bindToController: true,
        component: 'projectForm',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          customer: function () {
            return vm.customer;
          },
          project: function () {
            return project;
          },
        },
      });

      instance.result
        .then(function (result) {
          if (!result) {
            return;
          }

          $state.reload();
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }
})();
