; (function () {
  'use strict';

  const angular = window.angular;

  ResetPasswordController.$inject = ['Admin', '$state', 'AppUtils', '$rootScope'];

  angular
    .module('app')
    .component('publicResetPassword', {
      template:'<section class="login-container"><div class="login-header"><div class="login-brand"></div><h3 class="no-margin" translate>views.public.resetPassword.title</h3><p class="marginB0 marginT5" translate>views.public.resetPassword.subtitle</p></div><div class="login-content"><form class="login-form" ng-submit="$ctrl.sendRecoverEmail()" name="$ctrl.resetForm" ng-if="!$ctrl.success"><div class="form-group marginB20"><div class="input-group"><span class="input-group-addon"><i class="fas fa-envelope fa-fw"></i></span> <input ng-model="$ctrl.email" class="form-control" type="email" name="email" required translate-attr="::{placeholder: \'entities._all.email\'}"></div></div><button class="btn btn-login btn-lg btn-block" translate>buttons.send</button></form><div ng-if="$ctrl.success" class="text-center"><h3 class="text-success"><i class="far fa-check-circle fa-fw fa-2x"></i> <strong style="display: block" class="marginTB10" translate>views.public.resetPassword.emailSent</strong></h3><p class="marginB20 paddingB20" translate>views.public.resetPassword.message</p></div><div class="text-center marginT20"><a ui-sref="public.login" translate>buttons.signin</a></div><div ng-if="$ctrl.requestError" class="alert alert-danger marginT20 marginB0 padding5 text-center">{{\'errors.\' + $ctrl.requestError | translate}}</div></div></section>',
      controller: ResetPasswordController
    });

  function ResetPasswordController(Admin, $state, utils, $root) {
    let vm = this;

    this.$onInit = function () {
      vm.sendRecoverEmail = sendRecoverEmail;
      vm.error = $state.params.error;
    };

    function sendRecoverEmail() {
      if (!vm.resetForm.$valid) {
        return;
      }

      vm.success = false;
      vm.requestError = null;
      $root.appReady = false;
      Admin
        .resetPassword({ email: vm.email })
        .$promise
        .then(function () {
          $root.appReady = true;
          vm.success = true;
        })
        .catch(function (err) {
          const error = utils.getHTTPError(err);
          vm.requestError = error.code || err.message;
          if (vm.requestError === 'RESET_FAILED_EMAIL_NOT_VERIFIED') {
            $state.go('public.validateEmail');
          } else {
            $root.appReady = true;
          }
        });
    }
  }
})();
